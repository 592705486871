<template>
  <v-dialog
      v-model="dialog"
      persistent
      max-width="500px"
      transition="dialog-transition"
  >
       <v-card>
           <v-card-title primary-title class="letter-spacing">
               Dodawanie dyscypliny
           </v-card-title>
           <v-card-text class="py-2">
               <v-form v-model="valid">
                   <v-row no-gutters>
                       <v-col cols="12" class="py-0">
                            <v-text-field
                                v-model="form.name"
                                name="name"
                                label="Nazwa dyscypliny"
                                counter="150"
                                dense
                                outlined
                                :error-messages="errors.name"
                            ></v-text-field>
                       </v-col>
                   </v-row>
                   <v-row no-gutters>
                       <v-col cols="12" class="py-0">
                         <p class="text-center">
                            Typ dyscypliny
                        </p>
                         <v-btn-toggle
                            v-model="form.type"
                            class="d-flex justify-center"
                            mandatory
                        >
                            <v-btn width="200px" outlined active-class="blue white--text">Indywidualna</v-btn>
                            <v-btn width="200px" outlined active-class="blue white--text">Drużynowa</v-btn>
                        </v-btn-toggle>
                        <div class="v-messages theme--light error--text pl-7 pt-2" role="alert" v-if="errors.type"><div class="v-messages__wrapper"><div class="v-messages__message">{{errors.type}}</div></div></div>
                       </v-col>
                   </v-row>
               </v-form>
           </v-card-text>
           <v-card-actions class="pt-0">
               <v-spacer></v-spacer>
                      <v-btn
                        text
                        color="grey"
                        class="text-none letter-spacing"
                        @click="dialog = false"
                    >
                        Anuluj
                    </v-btn>
                    <v-btn
                        elevation="0"
                        color="primary"
                        class="text-none"
                        @click="sendForm"
                    >
                        Dodaj
                    </v-btn>
           </v-card-actions>
       </v-card>
  </v-dialog>
</template>

<script>
import dialog from '@/mixins/dialog.js';

export default {
    mixins: [dialog],
    data: () => ({
        valid: false,
        menu: false,
        form: {
            name: '',
            type: undefined,
        },
        errors:{
            name: '',
            type: ''
        }
    }),
    methods:{
        sendForm: function(){
            this.$http.post('/dyscypliny/create', {
                    name: this.form.name,
                    type: this.form.type,
                })
                .then(() => {
                    this.dialog = false;
                    this.$emit('created');
                })
                .catch(err => {
                    this.errors = err.response.data.body;
                })
        },
        clearForm: function(){
            this.form = {
                name: '',
                type: undefined
            };
            this.errors = {
                name: '',
                type: ''
            };
        }
    }
}
</script>

<style>

</style>