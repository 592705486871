<template>
    <v-container>
        <v-card flat class="pb-2">
            <v-card-title primary-title class="letter-spacing">
                Dyscypliny
                <v-spacer></v-spacer>
               <v-text-field
                    v-model="search"
                    name="search"
                    label="Wyszukaj dyscyplinę sportową"
                    outlined
                    dense
                    single-line
                    hide-details
                    clearable
                    clear-icon="fas fa-times"
                ></v-text-field>
                <v-btn color="primary" elevation="0" small outlined @click="creating = true" class="ml-2" height="40px">
                    <v-icon left size="14">
                        fas fa-plus
                    </v-icon>
                    Dodaj nową dyscyplinę
                </v-btn>
            </v-card-title>
            <v-card-text>
                <v-data-table
                    :headers="headers"
                    :items="items"
                    :search="search"
                    :disable-pagination="true"
                    :hide-default-footer="true"
                    :loading="loading"
                >
                    <template v-slot:[`item.type`]="{ item }">
                        <v-chip small color="blue" text-color="white" v-if="item.type == 0">Indywidualne</v-chip>
                        <v-chip small color="green darken-2" text-color="white" v-else-if="item.type == 1">Drużynowe</v-chip>
                    </template>
                </v-data-table>
            </v-card-text>
            <createModal :display="creating" @close="creating=false" @created="fetchAll"/>
        </v-card>
    </v-container>
</template>

<script>
import createModal from '@/components/dyscypliny/createModal.vue';

export default {
    components:{
        createModal
    },
    data: () => ({
        loading: false,
        search: '',
        achivements: false,
        creating: false,
        headers:[
            { text: 'Lp.', value: 'id',  align: 'start'},
            { text: 'Nazwa', value: 'name' },
            { text: 'Typ dyscypliny', value: 'type',  align: 'end'},
        ],
        items:[],
    }),
    methods:{
        fetchAll: function(){
            this.loading = true;
            this.$http.get('/dyscypliny/getList')
                .then(res => {
                    this.items = res.data.body;
                })
                .catch(err => {
                    console.log(err);
                })
                .finally(() => {
                    this.loading = false;
                })
        }
    },
    created(){
        this.fetchAll();
    }

}
</script>

<style>

</style>